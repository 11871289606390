import { useApolloClient } from "@apollo/client";
import React from "react";
import { ME } from "../common/gql";
import { useUser } from "../containers/AuthProvider";
import { Environment } from "./types";

export type { UrlParamsType, PageInterface } from "./types";
export { IsUserCustomerUser, IsCommissioningOrAdmin, IsCommissioningOnlyOrAdmin, IsReadOnlyUser, IsCommissioningNotDealer, IsDealerOrCommissioning };

function IsUserCustomerUser(): boolean {
  const client = useApolloClient();
  const [isCustomer, setIsCustomer] = React.useState<boolean>(false);
  const getRoleType = async () => {
    const { data } = await client.query({ query: ME });
    const isCustomer = data.userProfile.userRole.includes("Customer")
      ? true
      : false;
    setIsCustomer(isCustomer);
  };
  getRoleType();
  return isCustomer;
}

function IsReadOnlyUser(): boolean {
  const user = useUser();
  return user?.userRole === "OperatorReadOnly";
}

function IsDealerOrCommissioning(): boolean {
  const user = useUser();
  return (
    user.userRole === "Commissioning" ||
    user.userRole === "SystemAdmin" ||
    user.userRole === "CommissioningDealer" ||
    user.userRole === "Dealer"
  );
}

function IsCommissioningOrAdmin(): boolean {
  const user = useUser();
  return (
    user.userRole === "Commissioning" ||
    user.userRole === "SystemAdmin" ||
    user.userRole === "CommissioningDealer"
  );
}

function IsCommissioningNotDealer(): boolean {
  const user = useUser();
  return (
    user.userRole === "Commissioning" ||
    user.userRole === "SystemAdmin"
  );
}

function IsCommissioningOnlyOrAdmin(): boolean {
  const user = useUser();
  return (
    user.userRole === "Commissioning" ||
    user.userRole === "SystemAdmin"
  );
}

export function GetUserRole(): string {
  const client = useApolloClient();
  const [userRole, setUserRole] = React.useState<string>();
  const getRoleType = async () => {
    const { data } = await client.query({ query: ME });
    const userRole = data.userProfile.userRole;
    setUserRole(userRole);
  };
  getRoleType();
  return userRole as string;
}

export function IsUserSupervisor(role: string): boolean {
  return role === "Supervisor";
}

export function IsInternalCommissioningOrAdmin(): boolean {
  const user = useUser();
  return user.userRole === "Commissioning" || user.userRole === "SystemAdmin";
}


export function convertUtcToSiteTime(dateTime: string, siteUtcOffset: number): string {
  var date = new Date(dateTime);
  var offSet = dateTime.substring(23);
  var hourOffset = Number(offSet.substring(1, 3));
  if (offSet.substring(0, 1) === "-") hourOffset = -hourOffset;
  date.setTime(
    date.getTime() + (siteUtcOffset + hourOffset) * 60 * 60 * 1000
  );

  return date.toLocaleString("en-US", {
    timeZone: "UTC",
    hour12: false,
  });
};