import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Dialog } from "../components/Dialog";
import {
  GlobalNavigation,
  NavigationItemInterface,
} from "../components/Navigation";
import { QuickSearch } from "../components/QuickSearch";
import { ROUTES } from "../constants";
import { AuthContext } from "./AuthProvider";
import { IsUserCustomerUser, GetUserRole } from "../common";
import { useQuery } from "@apollo/client";
import { DEALER_HAS_CHILDREN, GET_POLICY_FOR_OPERATOR } from "../common/gql";
import Loader, { LoadingLayer } from "../components/Loader";
import { config } from "./Config";
import { DOES_USER_HAVE_METRICS } from "./Dashboard/gql";

const DashboardPolicyString: string = "POL-DASH";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  overflow: auto;
`;

const NavigationAndContent = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

const Content = styled.div`
  flex: 1 1 auto;
  position: relative;
  z-index: 1;
  min-width: 0;
  max-height: 100vh;
`;

const MainLayout: React.FunctionComponent<any> = (props) => {
  const { logout, user } = React.useContext(AuthContext);
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);

  const isCustomerUser = IsUserCustomerUser();
  const userRole = GetUserRole();

  const { loading: dealerLoading, data: dealerData } = useQuery(
    DEALER_HAS_CHILDREN,
    {
      fetchPolicy: "no-cache",
    }
  );

  const { data: policyValue, loading: policyLoading } = useQuery(
    GET_POLICY_FOR_OPERATOR,
    {
      variables: {
        operatorId: Number(user?.operatorId),
        policyReference: DashboardPolicyString,
      },
      fetchPolicy: "network-only",
    }
  );

  const { data: metricsValue, loading: metricsLoading } = useQuery(
    DOES_USER_HAVE_METRICS,
    {
      fetchPolicy: "network-only",
    }
  );

  const setQuickSearch = useCallback(
    (value: boolean) => () => {
      setSearchOpen(value);
    },
    []
  );

  const canUserAccessDashboardResult = React.useMemo(() => {
    if (!metricsValue || !policyValue) return false;
    return (
      metricsValue.doesUserHaveMetrics && policyValue.isOperatorOptedIntoPolicy
    );
  }, [metricsValue, policyValue]);

  if (dealerLoading || policyLoading || metricsLoading) {
    return (
      <LoadingLayer>
        <Loader display="inline-block" />
      </LoadingLayer>
    );
  }

  const hasChildren: boolean = dealerData?.operatorUserHasChildren != null;

  const MainNavigationItems: NavigationItemInterface[] =
    userRole === "Commissioning" || userRole === "SystemAdmin"
      ? [
          {
            label: "Sites",
            to: ROUTES.sites,
            iconName: "shield-off",
          },
          {
            label: "Dealers",
            to: ROUTES.dealers,
            iconName: "dealers",
          },
          {
            label: "Customers",
            to: ROUTES.customers,
            iconName: "users",
          },
          {
            label: "Commissioning",
            to: ROUTES.commissioningMenu,
            iconName: "communications",
          },
          {
            label: "Incident Reports",
            to: ROUTES.reports,
            iconName: "reports",
          },
          {
            label: "Service Requests",
            to: ROUTES.tasks,
            iconName: "tasks",
          },
          {
            label: "Dashboard",
            to: ROUTES.dashboard,
            iconName: "stats",
            isShown: canUserAccessDashboardResult,
          },
        ]
      : //SUPER DEALER ITEMS
      userRole === "Dealer" && hasChildren
      ? [
          {
            label: "Sites",
            to: ROUTES.sites,
            iconName: "shield-off",
          },
          {
            label: "Dealers",
            to: ROUTES.dealers,
            iconName: "dealers",
          },
          {
            label: "Customers",
            to: ROUTES.customers,
            iconName: "users",
          },
          {
            label: "Incident Reports",
            to: ROUTES.reports,
            iconName: "reports",
          },
          {
            label: "Service Requests",
            to: ROUTES.tasks,
            iconName: "tasks",
          },
          {
            label: "Book Commissioning",
            iconName: "documents",
            onClick: () =>
              window.open(
                config().REACT_APP_COMMISSIONING_BOOKING_EMAIL,
                "_blank"
              ),
          },
          {
            label: "Dashboard",
            to: ROUTES.dashboard,
            iconName: "stats",
            isShown: canUserAccessDashboardResult,
          },
        ]
      : //DEALER ITEMS
      userRole === "Dealer"
      ? [
          {
            label: "Sites",
            to: ROUTES.sites,
            iconName: "shield-off",
          },
          {
            label: "Customers",
            to: ROUTES.customers,
            iconName: "users",
          },
          {
            label: "Incident Reports",
            to: ROUTES.reports,
            iconName: "reports",
          },
          {
            label: "Service Requests",
            to: ROUTES.tasks,
            iconName: "tasks",
          },
          {
            label: "Book Commissioning",
            iconName: "documents",
            onClick: () =>
              window.open(
                config().REACT_APP_COMMISSIONING_BOOKING_EMAIL,
                "_blank"
              ),
          },
          {
            label: "Dashboard",
            to: ROUTES.dashboard,
            iconName: "stats",
            isShown: canUserAccessDashboardResult,
          },
        ]
      : //COMMISSIONING DEALER ITEMS
      userRole === "CommissioningDealer"
      ? [
          {
            label: "Sites",
            to: ROUTES.sites,
            iconName: "shield-off",
          },
          {
            label: "Customers",
            to: ROUTES.customers,
            iconName: "users",
          },
          {
            label: "Commissioning",
            to: ROUTES.commissioningMenu,
            iconName: "communications",
          },
          {
            label: "Incident Reports",
            to: ROUTES.reports,
            iconName: "reports",
          },
          {
            label: "Service Requests",
            to: ROUTES.tasks,
            iconName: "tasks",
          },
          {
            label: "Dashboard",
            to: ROUTES.dashboard,
            iconName: "stats",
            isShown: canUserAccessDashboardResult,
          },
        ]
      : //SUPERVISOR ITEMS
      userRole === "Supervisor" || userRole === "OperatorReadOnly"
      ? [
          {
            label: "Sites",
            to: ROUTES.sites,
            iconName: "shield-off",
          },
          {
            label: "Customers",
            to: ROUTES.customers,
            iconName: "users",
          },
          {
            label: "Incident Reports",
            to: ROUTES.reports,
            iconName: "reports",
          },
          {
            label: "Service Requests",
            to: ROUTES.tasks,
            iconName: "tasks",
          },
        ]
      : //DEFAULT
        [
          {
            label: "Sites",
            to: ROUTES.sites,
            iconName: "shield-off",
          },
          {
            label: "Incident Reports",
            to: ROUTES.reports,
            iconName: "reports",
          },
          {
            label: "Service Requests",
            to: ROUTES.tasks,
            iconName: "tasks",
          },
          {
            label: "Dashboard",
            to: ROUTES.dashboard,
            iconName: "stats",
            isShown: canUserAccessDashboardResult,
          },
        ];

  let AdminnavigationItems: NavigationItemInterface[] = isCustomerUser
    ? userRole === "CustomerBasic"
      ? [
          {
            label: "Change Password",
            to: ROUTES.changePassword,
            iconName: "lock",
          },
        ]
      : [
          {
            label: "Logs",
            to: ROUTES.logs,
            iconName: "logs",
          },
          {
            label: "Change Password",
            to: ROUTES.changePassword,
            iconName: "lock",
          },
        ]
    : userRole === "Supervisor"
    ? [
        {
          label: "Shared Contacts",
          to: ROUTES.sharedContacts,
          iconName: "dealers",
        },
        {
          label: "Logs",
          to: ROUTES.logs,
          iconName: "logs",
        },
        {
          label: "Change Password",
          to: ROUTES.changePassword,
          iconName: "lock",
        },
      ]
    : userRole === "Sales"
    ? [
        {
          label: "Shared Contacts",
          to: ROUTES.sharedContacts,
          iconName: "dealers",
        },
        {
          label: "Logs",
          to: ROUTES.logs,
          iconName: "logs",
        },
        {
          label: "Change Password",
          to: ROUTES.changePassword,
          iconName: "lock",
        },
      ]
    : [
        {
          label: "Users",
          to: ROUTES.users,
          iconName: "users",
        },
        {
          label: "Shared Contacts",
          to: ROUTES.sharedContacts,
          iconName: "dealers",
        },
        {
          label: "Logs",
          to: ROUTES.logs,
          iconName: "logs",
        },
        {
          label: "Change Password",
          to: ROUTES.changePassword,
          iconName: "lock",
        },
        {
          label: "Operator Settings",
          to: ROUTES.operator,
          iconName: "settings",
        },
      ];
  if (userRole == "Commissioning" || userRole == "SystemAdmin") {
    AdminnavigationItems.push({
      label: "Policy Admin Panel",
      to: ROUTES.policyAdminPanel,
      iconName: "adjust",
    });
  }

  const HelpNavigationItems: NavigationItemInterface[] = [
    {
      label: "Netwatch Training Academy",
      href: `${config().REACT_APP_TRAINING_ACADEMY_URL}`,
      target: "_blank",
    },
    {
      label: "Resource Center",
      href: `${config().REACT_APP_RESOURCE_CENTER_URL}`,
      target: "_blank",
    },
    {
      label: "Email Us",
      href: `${config().REACT_APP_EMAIL_US_MAILTO_EMAIL}`,
    },
  ];

  return (
    <Container>
      <NavigationAndContent>
        <GlobalNavigation
          onClickQuickSearch={setQuickSearch(true)}
          onClickLogout={() => logout()}
          isFetching={false}
          main={MainNavigationItems}
          admin={AdminnavigationItems}
          help={HelpNavigationItems}
          profile={user}
        />
        <Content>{props.children}</Content>
      </NavigationAndContent>
      <Dialog open={isSearchOpen}>
        <QuickSearch handleClose={setQuickSearch(false)} />
      </Dialog>
    </Container>
  );
};

export default MainLayout;
