import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import NavigationItem from "./NavigationItem";
import { useMediaQuery } from "react-responsive";
import { PageNavigationInterface } from "./types";
import { useCollapsed } from "../../hooks/useCollapsed";
import { palette } from "../../themes/palette";

const Divider = styled.div`
  display: flex;
  background: ${palette.white};
  height: 2px;
  margin: 8px;
  border-radius: 1px;
`;

const Container = styled.div<{ isCollapsed: Boolean }>`
  display: flex;
  flex-direction: column;
  ${({ isCollapsed }) => isCollapsed && "width: 64px"};
  height: 100vh;
  background: ${(props) => props.theme.navigator.page.background};
  transition: width 0.25s;
  filter: drop-shadow(2px 0px 4px rgba(37, 74, 93, 0.25));
  z-index: 1;
`;

const Header = styled.div<{ isCollapsed?: Boolean }>`
  display: flex;
  flex-direction: column;
  margin: 26px ${(props) => (props.isCollapsed ? 4 : 20)}px 8px 20px;
`;

const Title = styled.span<{ isCollapsed?: Boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.154px;
  color: ${(props) => props.theme.colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
  ${(props) => props.isCollapsed && "white-space: nowrap; overflow: hidden;"}
`;

const Subtitle = styled.span<{ isCollapsed?: Boolean }>`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-top: 2px;
  color: ${(props) => props.theme.colors.whiteSubtitle};
  ${(props) => props.isCollapsed && "white-space: nowrap; overflow: hidden;"}
`;

const Wrap = styled.div`
  margin-top: auto;
  padding-bottom: 12px;

  & div {
    margin-bottom: 10px;
  }

  & span {
  }
`;

function PageNavigation(props: PageNavigationInterface) {
  const history = useHistory();

  const key = "isCollapsed";
  const { isCollapsed, saveCollapse } = useCollapsed(key);
  const isMobileScreen = useMediaQuery({
    query:
      "(max-width: 420px) and (orientation: portrait), (max-height: 390px) and (orientation: landscape)",
  });

  React.useEffect(() => {
    if (isMobileScreen) {
      saveCollapse(true);
    }
  }, [isMobileScreen]);

  const { title, subtitle, backAction, links, isFetching } = props;

  return (
    <Container isCollapsed={isCollapsed}>
      <Header isCollapsed={isCollapsed}>
        <Title isCollapsed={isCollapsed}>{isCollapsed ? "" : title}</Title>
        <Subtitle isCollapsed={isCollapsed}>
          {isCollapsed ? "" : subtitle}
        </Subtitle>
      </Header>
      <NavigationItem
        appearance="page"
        isCollapsed={isCollapsed}
        label={backAction ? backAction.title : "Back"}
        iconName="arrow-left"
        onClick={() => !backAction && history.goBack()}
        exact={true}
        to={backAction && backAction.to}
      />
      <Divider />
      {links &&
        links.length &&
        links.map((itm, index) => (
          <NavigationItem
            appearance="page"
            isCollapsed={isCollapsed}
            key={`pageNavItm_${index}`}
            {...itm}
          />
        ))}
      <Wrap>
        <Divider />
        <NavigationItem
          appearance="page"
          isCollapsed={isCollapsed}
          label={isCollapsed ? "Expand" : "Collapse"}
          iconName={isCollapsed ? "chevron-right" : "chevron-left"}
          onClick={() => saveCollapse(!isCollapsed)}
        />
      </Wrap>
    </Container>
  );
}

export default PageNavigation;
